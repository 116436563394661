import fetch from "auth/FetchMasterInterceptor";
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
const leadsService = {};

// get student list
leadsService.getStudentList = function (params) {
  return fetch({
    url: "/students/list",
    method: "post",
    params,
  });
};

//add student
leadsService.addStudent = function (params) {
  return fetch({
    url: "/students/create",
    method: "post",
    params,
  });
};

//fatch student details
leadsService.detailsStudent = function (params) {
  return fetch({
    url: "/students/details",
    method: "post",
    params,
  });
};

//add or update student personal inforamtion
leadsService.personalInformationStudent = function (params) {
  return fetch({
    url: "/students/personal-information",
    method: "post",
    params,
  });
};

//add education history
leadsService.educationHistoryStudent = function (params) {
  return fetch({
    url: "/students/education-history",
    method: "post",
    data:params,
  });
};

//update education history
leadsService.educationHistoryUpdateStudent = function (params) {
  return fetch({
    url: "/students/education-history-update",
    method: "post",
    data:params,
  });
};

//add or update test score
leadsService.testScoreStudent = function (params) {
  return fetch({
    url: "/students/test-score",
    method: "post",
    params,
  });
};

//add or update student background information
leadsService.backgroundInfoStudent = function (params) {
  return fetch({
    url: "/students/background-info",
    method: "post",
    params,
  });
};

//add or update student documents
leadsService.documentsStudent = function (params) {
  return fetch({
    url: "/students/document/list",
    method: "post",
    data:params
  });
};

leadsService.documentsStudentUpload = function (params) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization":`Bearer ${jwtToken}`
    },
  };
  return axios
    .post(`${API_BASE_URL}/students/document/upload`, params, config);
};

//add or update student documents
leadsService.documentsStudentDelete = function (params) {
  return fetch({
    url: "/students/document/delete",
    method: "post",
    params,
  });
};

//add or update student question answers
leadsService.questionAnswerStudent = function (params) {
  return fetch({
    url: "/students/questionAnswer",
    method: "post",
    params,
  });
};


//apply application 
leadsService.applicationApplyStudent = function (params) {
  return fetch({
    url: "/students/application/apply",
    method: "post",
    params,
  });
};

//fatch apply application list
leadsService.applicationListStudent = function (params) {
  return fetch({
    url: "/students/application/list",
    method: "post",
    params,
  });
};

//update application status
leadsService.applicationStatusStudent = function (params) {
  return fetch({
    url: "/students/application/status",
    method: "post",
    params,
  });
};

//update application get status
leadsService.applicationGetStatusStudent = function (params) {
  return fetch({
    url: "/students/application/getStatus",
    method: "post",
    params,
  });
};
leadsService.deleteStudent = function (params) {
  return fetch({
    url: "/students/delete",
    method: "post",
    params,
  });
};
leadsService.applicationDetail = function (params) {
  return fetch({
    url: "/students/application/detail",
    method: "post",
    params,
  });
};
leadsService.applicationDelete = function (params) {
  return fetch({
    url: "/students/application/delete",
    method: "post",
    params,
  });
};
leadsService.documentsStudentStatus = function (params) {
  return fetch({
    url: "/students/document/status",
    method: "post",
    data:params
  });
};
leadsService.StudentStatus = function (params) {
  return fetch({
    url: "/students/status",
    method: "post",
    data:params
  });
};
leadsService.studentAssign = function (data) {
	return fetch({
		url: '/students/assign',
		method: 'post',
		data: data
	})
};
leadsService.applicationCredentialList = function (data) {
	return fetch({
		url: '/students/application/credentials/list',
		method: 'post',
		data: data
	})
};
leadsService.applicationCredentialCreate = function (data) {
	return fetch({
		url: '/students/application/credentials/create',
		method: 'post',
		data: data
	})
};
leadsService.applicationCredentialDelete = function (data) {
	return fetch({
		url: '/students/application/credentials/delete',
		method: 'post',
		data: data
	})
};

leadsService.getNotification = function (data) {
  return fetch({
    url: '/notification/list',
    method: 'post',
    data:data
  })
}

leadsService.addNotification = function (data) {
  //console.log(params)  
  return fetch({
      url: '/notification/create',
      method: 'post',
      data:data
    })
  }
  leadsService.editNotification = function (data) {
    return fetch({
      url: '/notification/edit',
      method: 'post',
      data:data
    })
  }
  leadsService.deleteNotification = function (data) {
    return fetch({
      url: '/notification/delete',
      method: 'post',
      data:data
    })
  }
  leadsService.getHeaderNofification = function (data) {
    return fetch({
      url: '/notification/getNotification',
      method: 'post',
      data:data
    })
  }
  leadsService.backShortListApplication = function (params) {
    return fetch({
      url: "/students/application/back",
      method: "post",
      params,
    });
  };

  leadsService.noticeStatus = function (params) {
    return fetch({
      url: "/notification/status",
      method: "post",
      params,
    });
  };
  leadsService.noticeRead = function (params) {
    return fetch({
      url: "/notification/read",
      method: "post",
      params,
    });
  };
  leadsService.applicationPriority = function (params) {
    return fetch({
      url: "/students/application/set-priority",
      method: "post",
      params,
    });
  };
  
export default leadsService;
