import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import leadsService from 'services/LeadsServices';



export const initialState = {
	loading: false,
	message: '',
	list:null,
}



export const navheaderListAll = createAsyncThunk('navheader/list',async (data, { rejectWithValue }) => {
	
	try {
		const reqeustParam = { page: 1, pageSize: 4 };
        const response = await leadsService.getHeaderNofification(reqeustParam)
		
        return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const navheaderListSlice = createSlice({
	name: 'navheaderlist',
	initialState,
	reducers: {
			
		navheaderList: (state, action) => {
			state.loading = true
			state.list = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(navheaderListAll.pending, (state) => {
                
				state.loading = false
			})
			.addCase(navheaderListAll.fulfilled, (state, action) => {
				
                state.loading = true
				state.list = action.payload
			})
			
	},
})

export const { 
	navheaderList,
    
    
} = navheaderListSlice.actions

export default navheaderListSlice.reducer