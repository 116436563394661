import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'studentlogin',
        path: `${AUTH_PREFIX_PATH}/student/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/student/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.coursetype',
        path: `${APP_PREFIX_PATH}/dashboards/coursetype`,
        component: React.lazy(() => import('views/app-views/dashboards/coursetype')),
    },
    {
        key: 'dashboard.course',
        path: `${APP_PREFIX_PATH}/dashboards/course`,
        component: React.lazy(() => import('views/app-views/dashboards/course')),
    },
    {
        key: 'dashboard.forms',
        path: `${APP_PREFIX_PATH}/dashboards/question`,
        component: React.lazy(() => import('views/app-views/dashboards/question')),
    },
    {
        key: 'dashboard.documents',
        path: `${APP_PREFIX_PATH}/dashboards/documents`,
        component: React.lazy(() => import('views/app-views/dashboards/documents')),
    },
    {
        key: 'dashboard.status',
        path: `${APP_PREFIX_PATH}/dashboards/status`,
        component: React.lazy(() => import('views/app-views/dashboards/status')),
    },
    {
        key: 'dashboard.getstatus',
        path: `${APP_PREFIX_PATH}/dashboards/getstatus`,
        component: React.lazy(() => import('views/app-views/dashboards/getstatus')),
    },
    {
        key: 'dashboard.partner',
        path: `${APP_PREFIX_PATH}/dashboards/university/partner`,
        component: React.lazy(() => import('views/app-views/dashboards/partner')),
    },
    {
        key: 'dashboard.college',
        path: `${APP_PREFIX_PATH}/dashboards/college`,
        component: React.lazy(() => import('views/app-views/dashboards/college')),
    },
    {
        key: 'dashboard.college-add',
        path: `${APP_PREFIX_PATH}/dashboards/college-add`,
        component: React.lazy(() => import('views/app-views/dashboards/college/collegeadd')),
    },
    {
        key: 'dashboard.college-edit',
        path: `${APP_PREFIX_PATH}/dashboards/college-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/college/collegeedit')),
    },
    {
        key: 'dashboard.college-course',
        path: `${APP_PREFIX_PATH}/dashboards/college/course/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/college/course')),
    },
    {
        key: 'dashboard.collegecourse-add',
        path: `${APP_PREFIX_PATH}/dashboards/college/course-add/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/college/course/collegecourseadd')),
    },
    {
        key: 'dashboard.collegecourse-edit',
        path: `${APP_PREFIX_PATH}/dashboards/college/course-edit/:cid/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/college/course/collegecourseedit')),
    },
	{
        key: 'dashboard.leads',
        path: `${APP_PREFIX_PATH}/dashboards/student`,
        component: React.lazy(() => import('views/app-views/dashboards/student')),
    },
    {
        key: 'dashboard.student.create',
        path: `${APP_PREFIX_PATH}/dashboards/student-add`,
        component: React.lazy(() => import('views/app-views/dashboards/student/studentadd')),
    },
	{
        key: 'dashboard.student.createedit',
        path: `${APP_PREFIX_PATH}/dashboards/student-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/student/studentedit')),
    },
    {
        key: 'dashboard.search.apply',
        path: `${APP_PREFIX_PATH}/dashboards/search-apply`,
        component: React.lazy(() => import('views/app-views/dashboards/student/components/SearchApply')),
    },
	{
        key: 'dashboard.college.import',
        path: `${APP_PREFIX_PATH}/dashboards/collegeimport`,
        component: React.lazy(() => import('views/app-views/dashboards/college/CollegeForm/importcsv')),
    },
    {
        key: 'dashboard.roles',
        path: `${APP_PREFIX_PATH}/dashboards/roles`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/role')),
    },
    {
        key: 'dashboard.roles.permission',
        path: `${APP_PREFIX_PATH}/dashboards/roles/permission/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/role/permission')),
    },
    {
        key: 'dashboard.permissions',
        path: `${APP_PREFIX_PATH}/dashboards/permissions`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/permission')),
    },
    {
        key: 'dashboard.users',
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/user')),
    },
    {
        key: 'dashboard.users.details',
        path: `${APP_PREFIX_PATH}/dashboards/users/details/:userId`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/user/details')),
    },
    {
        key: 'dashboard.users.employee',
        path: `${APP_PREFIX_PATH}/dashboards/users/employee/:parent_id`,
        component: React.lazy(() => import('views/app-views/dashboards/settings/user/employee')),
    },
    {
        key: 'dashboard.invoice',
        path: `${APP_PREFIX_PATH}/dashboards/invoice/`,
        component: React.lazy(() => import('views/app-views/dashboards/invoice')),
    },
    {
        key: 'dashboard.invoice',
        path: `${APP_PREFIX_PATH}/dashboards/invoice/create`,
        component: React.lazy(() => import('views/app-views/dashboards/invoice/create')),
    },
    {
        key: 'dashboard.invoice.edit',
        path: `${APP_PREFIX_PATH}/dashboards/invoice/edit/:invoice_id`,
        component: React.lazy(() => import('views/app-views/dashboards/invoice/edit')),
    },
    {
        key: 'dashboard.payouts',
        path: `${APP_PREFIX_PATH}/dashboards/payouts/`,
        component: React.lazy(() => import('views/app-views/dashboards/payouts')),
    },
    {
        key: 'dashboard.import.csv',
        path: `${APP_PREFIX_PATH}/dashboards/payouts/import-csv`,
        component: React.lazy(() => import('views/app-views/dashboards/payouts/importcsv')),
    },
    {
        key: 'dashboard.payouts.edit',
        path: `${APP_PREFIX_PATH}/dashboards/payouts/edit/:payout_id`,
        component: React.lazy(() => import('views/app-views/dashboards/payouts/edit')),
    },
	{
        key: 'dashboard.student.detail',
        path: `${APP_PREFIX_PATH}/dashboards/student-detail/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/student/studentdetail')),
    },
    {
        key: 'dashboard.application.detail',
        path: `${APP_PREFIX_PATH}/dashboards/application-detail/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/student/components/ApplicationView')),
    },
    {
        key: 'dashboard.change-password',
        path: `${APP_PREFIX_PATH}/dashboards/change-password`,
        component: React.lazy(() => import('views/app-views/dashboards/change-password')),
    },
    {
        key: 'dashboard.comment',
        path: `${APP_PREFIX_PATH}/dashboards/comment`,
        component: React.lazy(() => import('views/app-views/dashboards/comment')),
    },
    {
        key: 'dashboard.applicationlist',
        path: `${APP_PREFIX_PATH}/dashboards/application-list`,
        component: React.lazy(() => import('views/app-views/dashboards/student/ApplicationList')),
    },
    {
        key: 'dashboard.studentalllist',
        path: `${APP_PREFIX_PATH}/dashboards/student-list`,
        component: React.lazy(() => import('views/app-views/dashboards/student/StudentAllList')),
    },
    {
        key: 'dashboard.studentreportlist',
        path: `${APP_PREFIX_PATH}/dashboards/mis/student`,
        component: React.lazy(() => import('views/app-views/dashboards/report/student')),
    },
    {
        key: 'dashboard.studentreportlist',
        path: `${APP_PREFIX_PATH}/dashboards/mis/application`,
        component: React.lazy(() => import('views/app-views/dashboards/report/application')),
    },
    {
        key: 'dashboard.notice',
        path: `${APP_PREFIX_PATH}/dashboards/notice`,
        component: React.lazy(() => import('views/app-views/dashboards/student/components/Notice')),
    },
    {
        key: 'dashboard.membership',
        path: `${APP_PREFIX_PATH}/dashboards/check-membership`,
        component: React.lazy(() => import('views/app-views/dashboards/membership/membership')),
    },
    {
        key: 'dashboard.updates',
        path: `${APP_PREFIX_PATH}/dashboards/get-updates`,
        component: React.lazy(() => import('views/app-views/dashboards/updates')),
    },
    {
        key: 'dashboard.testimonial',
        path: `${APP_PREFIX_PATH}/dashboards/testimonials`,
        component: React.lazy(() => import('views/app-views/dashboards/testimonial')),
    },
    {
        key: 'dashboard.category',
        path: `${APP_PREFIX_PATH}/dashboards/category`,
        component: React.lazy(() => import('views/app-views/dashboards/category')),
    }



]